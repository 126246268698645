import React from 'react';
import { NavLink } from 'react-router-dom'

const mainMenu = [
  {
    "name": "Администрирование",
    "key": "menu_admin",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "Доступы",
        "key": "menu_accesses",
        "icon": "icon-note",
        "action": "/rules",
        "rules": "rules"
      },
      {
        "name": "Пользователи",
        "key": "menu_users",
        "icon": "icon-people",
        "action": "/sys_users",
        "rules": "rules"
      },
      {
        "name": "Устройства",
        "key": "menu_devices",
        "icon": "icon-people",
        "action": "/devices",
        "rules": "rules"
      },
      {
        "name": "Протоколы",
        "key": "menu_protocols",
        "icon": "icon-grid",
        "action": "/protocols",
        "rules": "rules"
      }
    ]
  }
]

const MenuItems = (props) => {
  if (props.items === null) return null;
  console.log(props.items);
  let v_ret = props.items.map(function (item, index) {
    if ("submenu" in item)
      return (
        <li key={item.key} className="dropdown"><a href="#"><i className={"me-2 " + item.icon}></i>{item.name}</a>
          <ul>
            <MenuItems items={item.submenu} />

          </ul>
        </li>
      );
    else
      return (
        <li key={item.key}><NavLink to={item.action}><i className={"me-2 " + item.icon}></i>{item.name}</NavLink></li>
      );
  });
  return v_ret;
}

const Menu = (props) => {
  console.log(props.menu);
  return (
    <div className="sidebar pt-0" data-bs-theme={(props.settings.themeColor ?? "light") === "light" ? "light" : "dark"}>
      <div className="site-width">
        <ul id="side-menu" className="sidebar-menu">
          {/* <MenuItems items={props.menu} /> */}
          <MenuItems items={mainMenu} />
        </ul>
      </div>
    </div>
  );
}

export default React.memo(Menu);
